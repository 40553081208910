<template>
  <div
    @mousewheel="mousewheel"
    class="loadingContainer"
    v-show="show"
    :style="{
      background: backgroundColor ? 'rgb(219, 147, 28)' : 'rgba(0,0,0, 0.4)',
    }"
  >
    <div class="loading" style="">
      <div style=""></div>
      <div style=""></div>
      <div style=""></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    mousewheel(e) {
      e.preventDefault();
    },
  },
};
</script>

<style scoped lang="scss">
.loadingContainer .loading {
  background-image: url("../assets/img/loading.png");
}
.loadingContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s;
  z-index: 99999;
}
.loadingContainer .loading {
  position: fixed;
  inset: 0px;
  background-color: rgba(255, 255, 255, 0.4);
  background-repeat: no-repeat;
  background-position: center 30px;
  background-size: 70px;
  margin: auto;
  text-align: center;
  animation: 1s linear 0s infinite normal none running waving;
  width: 100px;
  height: 100px;
  z-index: 1000;
  border-radius: 50%;
}
.loadingContainer .loading > div {
  background: #fff;
  width: 7px;
  height: 7px;
  border-radius: 8px;
  margin: 0 4px;
  opacity: 0;
  display: inline-block;
  animation: blink 1s infinite;
  margin-top: 65px;
}
.loadingContainer .loading > div:nth-child(2) {
  animation-delay: 0.2s;
}
.loadingContainer .loading > div:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes waving {
  0% {
    box-shadow: rgba(255, 255, 255, 1) 0 0 0px 0px,
      rgba(255, 255, 255, 1) 0 0 0px 0px, rgba(255, 255, 255, 1) 0 0 0px 0px;
  }
  17% {
    box-shadow: rgba(255, 255, 255, 1) 0 0 0px 5px,
      rgba(255, 255, 255, 1) 0 0 0px 0px, rgba(255, 255, 255, 1) 0 0 0px 0px;
  }
  34% {
    box-shadow: rgba(255, 255, 255, 0.5) 0 0 0px 10px,
      rgba(255, 255, 255, 1) 0 0 0px 5px, rgba(255, 255, 255, 1) 0 0 0px 0px;
  }
  51% {
    box-shadow: rgba(255, 255, 255, 0.25) 0 0 0px 15px,
      rgba(255, 255, 255, 0.5) 0 0 0px 10px, rgba(255, 255, 255, 1) 0 0 0px 5px;
  }
  68% {
    box-shadow: rgba(255, 255, 255, 0) 0 0 0px 15px,
      rgba(255, 255, 255, 0.25) 0 0 0px 15px,
      rgba(255, 255, 255, 0.5) 0 0 0px 10px;
  }
  85% {
    box-shadow: rgba(255, 255, 255, 0) 0 0 0px 15px,
      rgba(255, 255, 255, 0) 0 0 0px 15px,
      rgba(255, 255, 255, 0.25) 0 0 0px 15px;
  }
  100% {
    box-shadow: rgba(255, 255, 255, 0) 0 0 0px 15px,
      rgba(255, 255, 255, 0) 0 0 0px 15px, rgba(255, 255, 255, 0) 0 0 0px 15px;
  }
}

@keyframes blink {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
</style>
