<template>
  <div class="">
    <el-dialog
      :title="$t('consume.revise')"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
    >
      <el-form :model="form" ref="form" @submit.native.prevent>
        <el-form-item label="GiftCode:" :label-width="formLabelWidth">
          <el-input
            v-model="form.code"
            :on-change="(form.code = form.code.replace(/[^\w_]/g, ''))"
            :placeholder="$t('consume.enterCard')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm">
          {{ $t("consume.cardRecharge") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      form: {
        code: "",
      },
      formLabelWidth: "80px",
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    confirm() {
      if (!this.form.code) {
        this.$message.warning(this.$t('giftCode.enterCompleteInfo'));
        return;
      }
      let params = {
        code: this.form.code,
      };
      if (this.$route.path === "/purchase/index") {
        this.$http.post("/purchase/redeemCoupon", params).then((res) => {
          if (res.data.code === 200) {
            this.form = {
              code: "",
            };
            this.$parent.getProductDetail();
            this.$message.success(res.data.msg);
            this.dialogFormVisible = false;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.$http.post("/center/redeemCoupon", params).then((res) => {
          if (res.data.code === 200) {
            this.form = {
              code: "",
            };
            let product = this.$store.state.product;
            this.$parent.getProductDetail(product);
            this.$message.success(res.data.msg);
            this.dialogFormVisible = false;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },

    handleClose() {
      this.form = {
        code: "",
      };
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-dialog {
  border-radius: 32px !important;
}
/deep/.el-dialog__wrapper {
  text-align: center;
  white-space: nowrap;
  overflow: auto;
  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
  .el-dialog {
    margin: 30px auto !important;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    white-space: normal;
  }
}
@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    width: 90%;
  }
}
</style>
